@import "~antd/dist/antd.css";

.header {
  border: 1px solid rgb(235, 237, 240);
}

.ant-alert,
button {
  margin: 2rem 0;
}

.footer {
  margin: 0 1rem;
}
